import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

const MyTitle = ({ children, ...rest }) => <Modal.Title {...rest}>{children}</Modal.Title>

MyTitle.displayName = 'Title'

export default MyTitle

MyTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
