import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

const MyHeader = ({ children, ...rest }) => <Modal.Header {...rest}>{children}</Modal.Header>

MyHeader.displayName = 'Header'

export default MyHeader

MyHeader.propTypes = {
  children: PropTypes.node.isRequired,
}
