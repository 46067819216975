import { flow, types } from 'mobx-state-tree'
import axios from 'axios'

import Learner from '../models/LearnerModel'

const LearnerStore = types
  .model('Learner', {
    learners: types.map(Learner),
  })
  .actions(self => ({
    fetchLearner: flow(function* fetchLearner(id) {
      let res;
      try {
        res = yield axios.get(`http://localhost:3001/learner/${ id }`)
        self.Learner.put(res.data)
      } catch (e) {
        console.error('Error fetching learner: ', e)
      }
      return res.data
    }),
  }))
  .views(self => ({
    getLearner(id) {
      return self.learners.get(id)
    },
  }))

export default LearnerStore
