import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Validator from 'validatorjs'

import Form from './forms'
import { network } from '../util/constants'
import ServerErrorParser from '../util/ServerErrorParser'

const ValidatedForm = ({
  onSubmit,
  onError,
  data,
  rules,
  render,
}) => {
  // Use an empty errors object so the forms have access to its methods before any validation occurs.
  const [ errors, setErrors ] = useState(new Validator().errors)

  // The server does its own validation (weak passwords, duplicate usernames, etc).
  // Using a new variable for those errors is simple, but at the cost of maintaining two separate error variables.
  const [ serverErrors, setServerErrors ] = useState(new ServerErrorParser())

  // True when the form was submitted. Useful for when the form was submitted but still has errors.
  const [ submitted, setSubmitted ] = useState(false)

  // Useful for disabling form controls while the XHR is pending.
  const [ requestState, setRequestState ] = useState(network.DONE)

  const handleSubmit = e => {
    const validation = new Validator(data, rules)
    e.preventDefault()
    e.stopPropagation()

    if (validation.passes()) {
      if (onSubmit) {
        setRequestState(network.PENDING)
        onSubmit(e)
          .then(() => {
            setRequestState(network.DONE)
          })
          .catch(res => {
            if (res && res.errors) {
              // In this case, we can parse and display server errors.
              setServerErrors(new ServerErrorParser(res.errors))
            } else if (onError) {
              // In this case, we have some unknown error.
              // console.error(res)
              onError(res)
            }
            setRequestState(network.ERROR)
          })
      }
    }

    setErrors(validation.errors)
    setServerErrors(new ServerErrorParser())
    setSubmitted(true)
  }

  return (
    // We don't want built-in HTML validation to take over.
    <Form onSubmit={ handleSubmit } noValidate>
      { render({
        errors,
        serverErrors,
        requestState,
        submitted,
      }) }
    </Form>
  )
}

ValidatedForm.propTypes = {
  // The linter complains about "object" since it is too vague and prefers "shape".
  // We don't know the shape of the data or rules objects.
  // Both objects should be keys to strings.
  /* eslint-disable */
  data: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  /* eslint-enable */
  render: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
}

ValidatedForm.defaultProps = {
  onSubmit: null,
  onError: null,
}

export default ValidatedForm
