export const network = {
  PENDING: 'pending',
  DONE: 'done',
  ERROR: 'error',
}

export const roles = {
  UNDEFINED: 0,
  ADMIN: 1,
  LEARNER: 2,
}

export const labels = {
  COMPANY_NAME: 'Portia ABA Clinic Software Setup',
}

export const nodeEnv = {
  DEVELOPMENT: 'development',
  TEST: 'test',
  PRODUCTION: 'production',
}
