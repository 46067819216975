import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const MyFile = ({ children, ...rest }) => <Form.File {...rest}>{children}</Form.File>

MyFile.displayName = 'File'

export default MyFile

MyFile.propTypes = {
  children: PropTypes.node.isRequired,
}
