import Check from './Check'
import Control from './Control'
import Feedback from './Feedback'
import File from './File'
import Form from './Form'
import Group from './Group'
import Label from './Label'
import Row from './Row'

Form.Check = Check
Form.Control = Control
Form.Control.Feedback = Feedback
Form.File = File
Form.Group = Group
Form.Label = Label
Form.Row = Row

export default Form
