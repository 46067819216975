import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const MyForm = ({ children, ...rest }) => <Form {...rest}>{children}</Form>

MyForm.displayName = 'Form'

export default MyForm

MyForm.propTypes = {
  children: PropTypes.node.isRequired,
}
