import { types } from 'mobx-state-tree'
import Axios from 'axios'

import FormModel from './FormModel'

const LoginFormBase = types
  .model('LoginFormBase', {
    email: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    rememberMe: types.optional(types.boolean, false),
  })
  .actions(() => ({
    async submitForm(e) {
      const form = new FormData(e.target)
      return Axios({
        method: 'post',
        url: '/api/login',
        data: form,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        // todo
        // .then(res => {
        //   console.log(res)
        // })
        .catch(res => {
          // Watches for validation errors returned by the server.
          if (res.response && res.response.data) {
            throw res.response.data
          }
          throw res
        })
    },
  }))
  .views(() => ({
    get validationRules() {
      return {
        email: 'required|email|max:255',
        password: 'required',
      }
    },
  }))

const LoginFormModel = types
  .compose(FormModel, LoginFormBase)
  .named('LoginFormModel')

export default LoginFormModel
