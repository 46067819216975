import React, { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import {
  FaPlus, FaUser, FaUsers, FaBook, FaCalendar, FaChartBar, FaFolder, FaFileAlt, FaIdCard
} from 'react-icons/fa'
import { List } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../styles/StartView.module.css'
import Container from '../components/layouts/Container'
import Row from '../components/layouts/Row'
import Col from '../components/layouts/Col'

import Button from '../components/Button'
import InputGroup from '../components/inputs'
import Card from '../components/cards'
import modelOf from '../util/modelOf'
import Learner from '../models/LearnerModel'
import Form from '../components/forms'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const LearnersView = ({ model }) => {
  const classes = useStyles()
  const [ checked, setChecked ] = React.useState([ 0 ])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [ ...checked ];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  // const [ showModal, setShowModal ] = useState(false)
  // const handleShowModal = () => setShowModal(true)
  // const handleHideModal = () => setShowModal(false)
  const LearnerLevels = [
    {
      value: 'a',
      label: 'All Levels'
    },
    {
      value: 'a',
      label: 'White'
    },
    {
      value: 'b',
      label: 'Green'
    },
    {
      value: 'b',
      label: 'Yellow'
    },
    {
      value: 'b',
      label: 'Red'
    },
  ]
  const StateList = [
    {
      value: 'a',
      label: 'All Status'
    },
    {
      value: 'a',
      label: 'Active'
    },
    {
      value: 'b',
      label: 'Pending'
    },
    {
      value: 'b',
      label: 'Inactive'
    },
  ]

  return (
    <div className={ classNames('h-100 p-5', styles.container) }>
      <div>
        <h4>Learners
          <span className='float-right'>
            <Button><FaPlus />Add Learner</Button>
          </span>
        </h4>
      </div>
      <Form>
        <Form.Row>
          <Container>
            <Row>
              <Col xs={6} md={4}>
                <Form.Control as='select' className='form-control'>
                  {LearnerLevels.map(value => (
                    <option value={ value.value }>{value.label}</option>
                  ))}
                </Form.Control>
              </Col>
              <Col xs={6} md={4}>
                <Form.Control as='select'>
                  {StateList.map(value => (
                    <option value={ value.value }>{value.label}</option>
                  ))}
                </Form.Control>
              </Col>
              <Col xs={12} md={4}>
                <Form.Control placeholder='Search ...' />
              </Col>
            </Row>
          </Container>
        </Form.Row>
      </Form>
      <Card>
        <Card.Body>
          <List>
            {[ 0, 1, 2, 3 ].map(value => (
              <Card>
                <Card.Body>
                  <ListItem
                    key={value} role={undefined} dense
                    button onClick={handleToggle(value)}
                  >
                    <ListItemIcon>
                      <FaUser />
                    </ListItemIcon>
                    <ListItemText id={value} primary={`Learner ${ value + 1 }`} />
                    <ListItemSecondaryAction>
                      <IconButton href={`learner/${ value }/profile`} edge='end' aria-label='comments'>
                        <FaUser />
                      </IconButton>
                      <IconButton edge='end' aria-label='comments'>
                        <FaUsers />
                      </IconButton>
                      <IconButton edge='end' aria-label='comments'>
                        <FaBook />
                      </IconButton>
                      <IconButton edge='end' aria-label='comments'>
                        <FaCalendar />
                      </IconButton>
                      <IconButton edge='end' aria-label='comments'>
                        <FaChartBar />
                      </IconButton>
                      <IconButton edge='end' aria-label='comments'>
                        <FaFolder />
                      </IconButton>
                      <IconButton edge='end' aria-label='comments'>
                        <FaFileAlt />
                      </IconButton>
                      <IconButton edge='end' aria-label='comments'>
                        <FaIdCard />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Card.Body>
              </Card>
            ))}
          </List>
        </Card.Body>
      </Card>
    </div>
  )
}
LearnersView.propTypes = {
  model: modelOf(Learner).isRequired,
}

export default observer(LearnersView)
