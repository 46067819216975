import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

const MyDialog = ({ children, ...rest }) => <Modal.Dialog {...rest}>{children}</Modal.Dialog>

MyDialog.displayName = 'Dialog'

export default MyDialog

MyDialog.propTypes = {
  children: PropTypes.node.isRequired,
}
