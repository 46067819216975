import { types } from 'mobx-state-tree'

import { roles } from '../util/constants'

const Learner = types
  .model('Learner', {
    id: types.identifier,
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    level: types.optional(types.string, 'white'),
    status: types.optional(types.number, 1),
  })

export default Learner
