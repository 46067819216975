import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const MyCheck = ({ children, ...rest }) => <Form.Check {...rest}>{children}</Form.Check>

MyCheck.displayName = 'Check'

export default MyCheck

MyCheck.propTypes = {
  children: PropTypes.node,
}

MyCheck.defaultProps = {
  children: null,
}
