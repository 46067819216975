import React from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'

const MyCol = ({ children, ...rest }) => <Col {...rest}>{children}</Col>

MyCol.displayName = 'Col'

export default MyCol

MyCol.propTypes = {
  children: PropTypes.node.isRequired,
}
