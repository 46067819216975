import Append from './Append'
import Checkbox from './Checkbox'
import InputGroup from './InputGroup'
import Prepend from './Prepend'
import Radio from './Radio'
import Text from './Text'
// import Select from './Select'

InputGroup.Append = Append
InputGroup.Checkbox = Checkbox
InputGroup.Prepend = Prepend
InputGroup.Radio = Radio
InputGroup.Text = Text
// InputGroup.Select = Select

export default InputGroup
