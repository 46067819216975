import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const MyGroup = ({ children, ...rest }) => <Form.Group {...rest}>{children}</Form.Group>

MyGroup.displayName = 'Group'

export default MyGroup

MyGroup.propTypes = {
  children: PropTypes.node.isRequired,
}
