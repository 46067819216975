import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import { createUserStore } from '../util/factories'
import { network } from '../util/constants'

const userStore = createUserStore()

const UserController = () => {
  const { userId } = useParams()
  const user = userStore.getUser(userId)
  const shouldFetch = !user && userId
  const [ fetchState, setFetchState ] = useState(
    shouldFetch && user ? network.DONE : network.PENDING,
  )

  useEffect(() => {
    if (!shouldFetch) return
    userStore
      .fetchUser(userId)
      .then(() => setFetchState(network.DONE))
      .catch(() => setFetchState(network.ERROR))
  }, [ userId, user, shouldFetch ])

  return (
    <>
      <h1>User Page Title</h1>
      { fetchState === network.PENDING
        && userId
        && <h2>Loading user...</h2> }
      { fetchState === network.ERROR
        && <h2>Error!</h2> }
      { user
        && <h2>User: { user.first_name } { user.last_name }</h2> }
    </>
  )
}

export default observer(UserController)
