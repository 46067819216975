import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const MyLabel = ({ children, ...rest }) => <Form.Label {...rest}>{children}</Form.Label>

MyLabel.displayName = 'Label'

export default MyLabel

MyLabel.propTypes = {
  children: PropTypes.node.isRequired,
}
