import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'

const MyContainer = ({ children, ...rest }) => <Container {...rest}>{children}</Container>

MyContainer.displayName = 'Container'

export default MyContainer

MyContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
