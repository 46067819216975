import React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'

const MyAppend = ({ children, ...rest }) => <InputGroup.Append { ...rest }>{ children }</InputGroup.Append>

MyAppend.displayName = 'Append'

MyAppend.propTypes = {
  children: PropTypes.node,
}

MyAppend.defaultProps = {
  children: null,
}

export default MyAppend
