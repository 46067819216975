import { types } from 'mobx-state-tree'

import { roles } from '../util/constants'

const User = types
  .model('User', {
    id: types.identifier,
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    role: types.optional(types.number, roles.UNDEFINED),
  })

export default User
