import React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'

const MyText = ({ children, ...rest }) => <InputGroup.Text { ...rest }>{ children }</InputGroup.Text>

MyText.displayName = 'Text'

MyText.propTypes = {
  children: PropTypes.node,
}

MyText.defaultProps = {
  children: null,
}

export default MyText
