import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

const MyFooter = ({ children, ...rest }) => <Modal.Footer {...rest}>{children}</Modal.Footer>

MyFooter.displayName = 'Footer'

export default MyFooter

MyFooter.propTypes = {
  children: PropTypes.node.isRequired,
}
