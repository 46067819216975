import React, { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FaAngleDoubleRight } from 'react-icons/fa'

import Button from '../components/Button'
import Card from '../components/cards'
import Col from '../components/layouts/Col'
import Icon from '../components/Icon'
import Form from '../components/forms'
import Modal from '../components/modals'
import RegisterFormModel from '../models/RegisterFormModel'
import UserAgreement from '../components/UserAgreement'
import ValidatedForm from '../components/ValidatedForm'

import { labels, network } from '../util/constants'
import styles from '../styles/StartView.module.css'
import modelOf from '../util/modelOf'

// todo
// Several input fields have been disabled because they have not yet been implemented on the server.
const RegisterView = ({ model }) => {
  const [ showModal, setShowModal ] = useState(false)
  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)
  return (
    <div className={ classNames('h-100 p-5', styles.container) }>
      <Modal
        centered
        show={ showModal }
        onHide={ handleHideModal }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>An unexpected error has occurred. Please try again later.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleHideModal }>Close</Button>
        </Modal.Footer>
      </Modal>
      <Card lg className={ styles.card }>
        <Card.Header>{ labels.COMPANY_NAME }</Card.Header>
        <Card.Body>
          <ValidatedForm
            onSubmit={ model.submitForm }
            onError={ handleShowModal }
            data={ model.formState }
            rules={ model.validationRules }
            render={ ({ errors, serverErrors, requestState }) => (
              <>
                <div className='font-weight-bold mb-4'>Sign up information</div>
                <legend>Your information</legend>
                <hr />
                <Form.Row>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      placeholder='First name'
                      name='first_name'
                      isInvalid={ errors.has('first_name') }
                      { ...model.getFieldProps('first_name') }
                    />
                    <Form.Control.Feedback type='invalid'>
                      { errors.first('first_name') }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      placeholder='Last name'
                      name='last_name'
                      isInvalid={ errors.has('last_name') }
                      { ...model.getFieldProps('last_name') }
                    />
                    <Form.Control.Feedback type='invalid'>
                      { errors.first('last_name') }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Email'
                      name='email'
                      isInvalid={ errors.has('email') || serverErrors.has('email') }
                      { ...model.getFieldProps('email') }
                    />
                    <Form.Control.Feedback type='invalid'>
                      { errors.first('email') }
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>
                      { serverErrors.first('email') }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      name='password'
                      isInvalid={ errors.has('password') || serverErrors.has('password') }
                      { ...model.getFieldProps('password') }
                    />
                    <Form.Control.Feedback type='invalid'>
                      { errors.first('password') }
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>
                      { serverErrors.first('password') }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Confirm password'
                      name='password_confirmation'
                      isInvalid={ errors.has('password_confirmation') }
                      { ...model.getFieldProps('password_confirmation') }
                    />
                    <Form.Control.Feedback type='invalid'>
                      { errors.first('password_confirmation') }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <legend>Faculty information</legend>
                <hr />
                <Form.Row>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Faculty name</Form.Label>
                    <Form.Control placeholder='Faculty name' disabled />
                  </Form.Group>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Faculty address</Form.Label>
                    <Form.Control placeholder='Faculty address' disabled />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Website</Form.Label>
                    <Form.Control placeholder='Website' disabled />
                  </Form.Group>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Country</Form.Label>
                    <Form.Control placeholder='Country' disabled />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>State</Form.Label>
                    <Form.Control placeholder='State' disabled />
                  </Form.Group>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>City</Form.Label>
                    <Form.Control placeholder='City' disabled />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={ Col } xs={ 12 } md={ 6 }>
                    <Form.Label>Postal code</Form.Label>
                    <Form.Control placeholder='Postal code' disabled />
                  </Form.Group>
                </Form.Row>
                <legend>User agreement</legend>
                <hr />
                <div className={ classNames('mb-3', styles.userAgreement) }>
                  <UserAgreement />
                </div>
                <div className='text-center'>
                  <Button
                    type='submit'
                    variant='success'
                    className='mb-3'
                    disabled={ requestState === network.PENDING }
                  >
                    <span className='align-middle'>Accept&nbsp;</span>
                    <Icon variant='light'><FaAngleDoubleRight /></Icon>
                  </Button>
                  <br />
                  <small>
                    By clicking the &quot;Accept&quot; button you agree to our user agreement presented above.
                  </small>
                </div>
              </>
            ) }
          />
        </Card.Body>
      </Card>
    </div>
  )
}

RegisterView.propTypes = {
  model: modelOf(RegisterFormModel).isRequired,
}

export default observer(RegisterView)
