import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardSubtitle = ({ children, ...rest }) => (
  <Card.Subtitle {...rest}>{children}</Card.Subtitle>
)

MyCardSubtitle.displayName = 'Card.Subtitle'

export default MyCardSubtitle

MyCardSubtitle.propTypes = {
  children: PropTypes.node.isRequired,
}
