import React from 'react'
import {
  Switch,
  Route,
} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'

import './util/initialize'
import HomeController from './controllers/HomeController'
import LoginController from './controllers/LoginController'
import RegisterController from './controllers/RegisterController'
// import LogoutPageController from './controllers/LogoutPageController'
import UserController from './controllers/UserController'
import LearnerController from './controllers/LearnerController'

const App = () => (
  <>
    <CssBaseline />
    <Switch>
      <Route exact path='/' component={ HomeController } />
      <Route exact path='/login' component={ LoginController } />
      <Route exact path='/register' component={ RegisterController } />
      <Route exact path='/learner' component={ LearnerController } />
      <Route exact path='/learner/:learnerId/:tabb' component={ LearnerController } />
      <Route exact path='/learner/:learnerId/:tabb/:deeperTabb' component={ LearnerController } />
      {/* <Route exact path='/logout' component={ LogoutPageController } /> */}
      { <Route exact path='/users/:userId' component={ UserController } /> }
      { <Route exact path='/users' component={ UserController } /> }
    </Switch>
  </>
)

export default App
