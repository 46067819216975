import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardText = ({ children, ...rest }) => <Card.Text {...rest}>{children}</Card.Text>

MyCardText.displayName = 'Card.Text'

export default MyCardText

MyCardText.propTypes = {
  children: PropTypes.node.isRequired,
}
