import React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'

const MyPrepend = ({ children, ...rest }) => <InputGroup.Prepend { ...rest }>{ children }</InputGroup.Prepend>

MyPrepend.displayName = 'Prepend'

MyPrepend.propTypes = {
  children: PropTypes.node,
}

MyPrepend.defaultProps = {
  children: null,
}

export default MyPrepend
