import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardBody = ({ children, ...rest }) => <Card.Body {...rest}>{children}</Card.Body>

MyCardBody.displayName = 'Card.Body'

export default MyCardBody

MyCardBody.propTypes = {
  children: PropTypes.node.isRequired,
}
