import UserStore from '../stores/UserStore'
import LearnerStore from '../stores/LearnerStore'

let userStore = null;
let learnerStore = null;

// todo: We won't need to disable this lint error after we export multiple functions.
// eslint-disable-next-line
export function createUserStore() {
  if (userStore) {
    return userStore
  }

  userStore = UserStore.create({ users: {} })
  return userStore
}

export function createLearnerStore() {
  if (learnerStore) {
    return learnerStore
  }

  learnerStore = LearnerStore.create({ learner: {} })
  return learnerStore
}
