import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

import styles from '../../styles/Modal.module.css'

const MyModal = ({ children, size, ...rest }) => {
  const sizeClassName = size ? styles[size] : styles.md
  return (
    <Modal dialogClassName={ sizeClassName } { ...rest }>
      { children }
    </Modal>
  )
}

MyModal.displayName = 'Modal'

MyModal.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
}

MyModal.defaultProps = {
  size: 'md',
}

export default MyModal
