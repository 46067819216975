import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardTitle = ({ children, ...rest }) => <Card.Title {...rest}>{children}</Card.Title>

MyCardTitle.displayName = 'Card.Title'

export default MyCardTitle

MyCardTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
