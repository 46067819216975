import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const MyFeedback = ({ children, ...rest }) => <Form.Control.Feedback { ...rest }>{ children }</Form.Control.Feedback>

MyFeedback.displayName = 'Feedback'

MyFeedback.propTypes = {
  children: PropTypes.node,
}

MyFeedback.defaultProps = {
  children: null,
}

export default MyFeedback
