import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardLink = ({ children, ...rest }) => <Card.Link {...rest}>{children}</Card.Link>

MyCardLink.displayName = 'Card.Link'

export default MyCardLink

MyCardLink.propTypes = {
  children: PropTypes.node.isRequired,
}
