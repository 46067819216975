import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import { createLearnerStore } from '../util/factories'
import { network } from '../util/constants'
import LearnersView from '../views/LearnersView'

const learnerStore = createLearnerStore()

const LearnerController = () => {
  const { learnerId, tabb, deeperTabb } = useParams()
  const learner = learnerStore.getLearner(learnerId)
  const shouldFetch = !learner && learnerId
  const [ fetchState, setFetchState ] = useState(
    shouldFetch && learner ? network.DONE : network.PENDING,
  )

  useEffect(() => {
    if (!shouldFetch) return
    learnerStore
      .fetchLearner(learnerId)
      .then(() => setFetchState(network.DONE))
      .catch(() => setFetchState(network.ERROR))
  }, [ learnerId, learner, shouldFetch ])

  return (
    <>
      <LearnersView model={learner} />

      { fetchState === network.PENDING
        && learnerId
        && <h2>Loading learner...</h2> }
      { fetchState === network.ERROR
        && <h2>Error!</h2> }
      { learner
        && <h2>Learner: { learner.first_name } { learner.last_name }</h2> }
    </>
  )
}

export default observer(LearnerController)
