import React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'

const MyCheckbox = ({ children, ...rest }) => <InputGroup.Checkbox { ...rest }>{ children }</InputGroup.Checkbox>

MyCheckbox.displayName = 'Checkbox'

MyCheckbox.propTypes = {
  children: PropTypes.node,
}

MyCheckbox.defaultProps = {
  children: null,
}

export default MyCheckbox
