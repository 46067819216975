import React, { useEffect, useState } from 'react'

import RegisterView from '../views/RegisterView'
import RegisterFormModel from '../models/RegisterFormModel'

const RegisterController = () => {
  const [ model, setModel ] = useState()

  // Empty array means this effect only runs once
  useEffect(() => {
    setModel(RegisterFormModel.create())
  }, [])

  return model ? <RegisterView model={ model } /> : null
}

export default RegisterController
