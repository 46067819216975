import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const MyControl = ({ children, ...rest }) => <Form.Control { ...rest }>{ children }</Form.Control>

MyControl.displayName = 'Control'

MyControl.propTypes = {
  children: PropTypes.node,
}

MyControl.defaultProps = {
  children: null,
}

export default MyControl
