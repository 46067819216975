import React from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'

const MyRow = ({ children, ...rest }) => <Row {...rest}>{children}</Row>

MyRow.displayName = 'Row'

export default MyRow

MyRow.propTypes = {
  children: PropTypes.node.isRequired,
}
