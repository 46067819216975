import React from 'react'

// todo
// This should be implemented via code splitting. It's a massive component we don't want to be loaded unless it
// absolutely needs to be.

// Don't care about linting this
/* eslint-disable */
const UserAgreement = () => (
  <>
    <p className='text-center'>ADMINISTRATOR SOFTWARE LICENSE AGREEMENT</p>
    <p className='text-center'>(MASTER SERVICES LICENCE AGREEMENT)</p>
    <p>This administrator software license agreement (the Agreement) is a legal agreement by and between Portia
      International Inc. (PORTIA) and you, the individual or the entity that you represent (Customer),
      concerning your access to and use of this software product (the Software).
      BY SELECTING “I AGREE” CUSTOMER IS INDICATING ITS ACCEPTANCE OF, AND CUSTOMER AGREES TO BE BOUND BY, THE
      TERMS AND CONDITIONS OF THIS AGREEMENT WHICH SHALL GOVERN THE ACCESS AND USE OF THE SOFTWARE BY CUSTOMER
      AND ITS AUTHORIZED USERS (AS DEFINED HEREIN) DURING THE TERM OF THIS AGREEMENT. IF CUSTOMER DOES NOT AGREE
      TO THIS AGREEMENT, CUSTOMER’S ACCESS AND USE OF THE SOFTWARE WILL NOT BE PERMITTED.</p>

    <p>In consideration of the mutual promises, covenants, and conditions contained in this Agreement and for
      other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged,
      PORTIA and Customer agree as follows:</p>

    <p>1.0 LICENSE GRANT AND RESTRICTIONS</p>
    <p>1.1 License. PORTIA hereby grants to Customer, subject to the terms and conditions of this Agreement, a
      worldwide, limited, non-exclusive, non-transferable and non-assignable license to use the Software for the
      Permitted Purpose (as defined herein) (the Licence).</p>
    <p>1.2 Permitted Purpose. In this Agreement, Permitted Purpose means to: (a) access and use the Software
      remotely through the Internet or other agreed-to communications network solely for Customer’s internal
      business purposes as an “administrator” in connection with the treatment and instruction of individuals
      under the care and treatment of Customer (each a Patient), subject to any limitations, restrictions or
      requirements specified herein; and (b) allow the Authorized Users (as defined herein) to access and use
      the Software through the Internet or other agreed-to communications network solely in connection with
      Customer’s internal business purposes and subject to any limitations, restrictions or requirements
      specified herein.</p>
    <p>1.3 Documentation.The foregoing License shall include the right of Customer and each of Customer’s
      Authorized Users, to use and copy, for its own purposes in connection with its use of the Software and any
      related and available documentation, including the user guide provided by PORTIA to Customer (in any form)
      (the Documentation). For certainty, the foregoing does not oblige PORTIA to provide any Documentation to
      Customer.</p>
    <p>1.4 Restrictions on Use. Except as otherwise permitted by this Agreement, Customer’s access and use of
      the Software is subject to the following restrictions and limitations: (a) Customer shall limit access to
      the Software to its employees, contractors and agents and Authorized Users who require such access in
      order to permit Customer to use the Software [and Documentation] as expressly permitted under this
      Agreement; (b) Customer shall not, except as may be expressly provided herein, and shall ensure the
      Authorized Users do not: (i) provide, disclose, sublicense, lease, rent or otherwise permit any person
      other than Customer or a Authorized User to access, use, read, disseminate, transmit, distribute or
      reproduce the Software or [Documentation]; (ii) adapt, translate, change, customize, enhance, augment,
      partially delete or alter, or otherwise modify the Software in any manner or to any extent whatsoever,
      whether in whole or in part; (iii) disassemble, decompile, reverse engineer, or otherwise in any manner
      deconstruct all or any part of the Software; (iv) transfer, sell, assign, or otherwise convey the Software
      to any party except as may be otherwise expressly provided for herein; or (iv) alter any proprietary
      notices appearing in the Software.</p>
    <p>1.5 Access by Authorized Users. Customer may invite senior therapists, instructor therapists, and
      parents/guardians of patients (each an Authorized User) to access and/or use the Software remotely via the
      Internet or other agreed-to communications network solely for the purpose of allowing such Authorized User
      to support, or in connection with, Customer’s internal business purposes. Customer agrees that any access
      or use of the Software by any Authorized User shall be subject to: (a) each Authorized User, prior to
      being able to access and/or use the Software, agreeing with PORTIA to terms and conditions of service
      governing such Authorized User’s access to and use of the Software; (b) Customer promptly notifying PORTIA
      of any non-compliant or suspected non-compliant access to or use of the Software by any Authorized User;
      and (c) Customer remaining fully responsible and liable to PORTIA in respect of any Authorized User’s
      access or use of the Software and any failure by an Authorized User to comply with applicable Laws (as
      defined herein) and the terms of this Agreement.</p>
    <p>1.6 Compliance with Laws. Customer shall comply with all laws, statutes, codes, treaties ordinances,
      orders, decrees, rules, regulations and municipal by-laws, judicial, administrative, ministerial,
      departmental or regulatory judgments, orders, decisions, rulings or awards of any governmental authority,
      policies, guidelines and protocols (Laws), including the Personal Information Protection and Electronic
      Documents Act (Canada), the Personal Health Information Protection Act, 2004 (Ontario) and any similar
      laws governing the protection of personal information or personal health information (Privacy Laws).</p>
    <p>1.7 Customer Responsibilities. Customer is solely responsible for: (a) ensuring that devices used to
      access the Software meet the system requirements established by PORTIA from time to time; (b) monitoring
      and controlling the activity of each Authorized User; (c) ensuring each Authorized User maintains the
      security of any password, username, or other form of authentication involved in obtaining access to the
      Software; (d) ensuring that there is no unauthorized access to the Software and notifying PORTIA promptly
      of any such access of which Customer becomes aware; and (e) the reliability, accuracy, quality, integrity
      and legality of all Data (as defined herein) and the means by which Customer and each Authorized User
      obtains, collects, uses and discloses such Data. Customer agrees that its access or use of the Software
      shall be subject to agreeing with PORTIA to terms and conditions of service governing Customer’s access to
      and use of the Software, and that in the event that there is any conflict between the terms of this
      Agreement and such terms and conditions of service regarding Customer access and use of the Software, the
      terms of this Agreement shall govern.</p>
    <p>1.8 No Support. Customer acknowledges that PORTIA is not obligated hereunder to provide any support or
      maintenance (including to correct any errors or defects in the Software) or to provide any Updates, as
      defined herein, to or in respect of the Software. At its sole discretion, PORTIA may elect to provide
      technical support for the Software to Customer.</p>
    <p>1.9 Improvements. PORTIA may, but is not required to, provide to Customer improvements, enhancements,
      modifications, updates, and patches to the Software (Upgrades) to: (a) improve upon or repair existing
      features and operations within the Software; (b) modify the Software to comply with applicable Laws; or
      (iii) add enhanced functionality to the Software. Customer acknowledges that PORTIA may apply any Upgrades
      to the Software as such Upgrades are developed and released to PORTIA’S general user base. PORTIA reserves
      the sole and exclusive right to determine how and when to develop and apply any Upgrades. PORTIA retains
      the sole and exclusive discretion to provide new functionality as an Upgrade to existing Software or as
      separate, stand-alone software. Customer may request improvements or enhancements to the Software which
      PORTIA may provide at extra charge to Customer, in its sole discretion, at its then current standard
      software development rates as established by PORTIA from time to time as subsequently agreed to by PORTIA
      and Customer in writing. Any amounts that may be due and payable by Customer under this Section 1.9 shall
      be invoiced in advanced and due immediately upon receipt of PORTIA’s invoice unless the parties hereto
      otherwise agree in writing.</p>
    <p>1.10 Hosting Services. PORTIA shall cause the Software to be maintained on computer servers and equipment
      selected by PORTIA, such that the Software is available to Customer through the Internet. Customer
      acknowledges that Data (as defined herein) may be transferred, processed and stored on servers in
      countries outside of Canada, including the United States, and may therefore be subject to different laws
      than those applicable in Canada.</p>
    <p>1.11 Additional Services. All costs and expenses incurred by Customer in its operation and use of the
      Software shall be borne solely by Customer. In the event that Customer requests that PORTIA provide
      Customer with any assistance, technical support, services or training in connection with Customer’s use of
      the Software, any such assistance, services or training will be provided at PORTIA’s sole discretion and
      to the extent that PORTIA is unwilling to provide such assistance, services or training free of charge,
      may be provided at extra charge to, and paid for by, Customer, provided that PORTIA shall obtain the
      written approval of Customer prior to incurring any additional costs or expenses for which it seeks
      reimbursement. Any amounts that may be due and payable by Customer under this Section 1.11 shall be
      invoiced in advanced and due immediately upon receipt of PORTIA’s invoice unless the parties hereto
      otherwise agree in writing.</p>
    <p></p>
    <p>2.0 PAYMENT</p>
    <p>2.1 License Fees. In consideration of PORTIA’S provision of the Software, Customer shall pay to PORTIA
      the then current applicable monthly license fee for each Patient of Customer (the License Fees). License
      Fees are subject to change upon thirty (30) days’ notice from PORTIA to Customer, which notice may be
      provided to Customer by PORTIA. The monthly License Fee shall be calculated based on the actual total
      number of Patient profiles inputted into the Software at any time during the preceding month, which number
      shall be determined exclusively by PORTIA. Where a Patient profile is added after the fifteenth (15th) day
      of the preceding month, the Licensee Fee shall be one-half of the usual monthly per Patient License
      Fee.</p>
    <p>2.2 Payment. License Fees shall be paid by Customer on the first day of each month during the Term in
      advance for each month in which access to the Software is provided by PORTIA. License Fees must be paid
      and pre-approved using a valid credit card acceptable to PORTIA in its sole discretion prior to PORTIA
      continuing to make the Software available to Customer. PORTIA will provide Customer with notice of the
      amount of Licensee Fees at least ten (10) days prior to the date of the pre-approved payment of the
      License Fees for the applicable month. Customer consents and agrees to be billed for, and to pay, License
      Fees automatically on a recurring basis in compliance with applicable Laws and the card association and
      network rules of Visa Europe Ltd, Visa U.S.A., Inc., Visa Canada Inc. and Visa International, MasterCard
      International Incorporated, Discover, American Express or other applicable card network. Credit card
      payments are processed for PORTIA by a third party payment processor called “STRIPE” (the Processor) and,
      as such, Customer’s Personal Information (as defined herein), and financial, credit card, or transaction
      information may be transferred, processed and stored by the Processor outside of Canada and may be subject
      to disclosure as required by applicable Law in accordance with Portia’s then current privacy policy.
      Customer hereby authorizes PORTIA and/or the Processor to share information with law enforcement about
      Customer, Customer’s transactions, or Customer’s use of the Software if PORTIA and/or the Processor
      reasonably suspect Customer’s account has been, or may be, used for an unauthorized, illegal, or criminal
      purpose. PORTIA reserves the right to not authorize or settle any payment for any License Fees that PORTIA
      believes, in its sole discretion, is in violation of this Agreement or any agreement between PORTIA and
      the Processor, or exposes Customer, PORTIA, other users of the Software (including Authorized Users), or
      other users of services provided by the Processor to harm. Customer may cancel any pre-approved payment
      within three (3) Business Days of the scheduled date of the pre-approved License Fees payment, however
      Customer shall remain liable to PORTIA for the amount of such Licensee Fees payment and shall pay PORTIA
      by alternative means.</p>
    <p>2.3 No Refund. Licensee Fees are non-refundable, in whole or in part, except where required by applicable
      Laws. Customer’s obligation to pay License Fees continues through the end of the month during which it
      cancels its subscription.</p>
    <p>2.4 Taxes. All amounts payable by Customer pursuant to this Agreement are exclusive of any sales, goods
      and services, harmonized sales, value-added, stamp, customs, transfer, consumption, use, excise or similar
      taxes, duties or charges (collectively, Taxes) and the parties hereto agree that Customer shall be liable
      for all Taxes payable in connection with the transactions contemplated by this Agreement.</p>
    <p>2.5 Additional Payment Terms. If Customer has failed to pay any fees or amounts under this Agreement
      within thirty (30) days of their due date such unpaid amount shall bear interest from the due date to the
      date of payment at the rate of one percent (1.0%) per month (being 12% per annum) or the maximum interest
      rate permitted under applicable Laws if less, such interest to accrue from day to day and to be compounded
      on a monthly basis. If Customer has failed to pay any undisputed fees or amounts under this Agreement
      within sixty (60) calendar days of their due date, such failure shall be deemed to be a material breach of
      this Agreement and PORTIA may terminate this Agreement or suspend Customer’s (and all Authorized Users’)
      access to, and use of, the Software. Payment shall be made without any right of set-off or deduction.
      Customer confirms that in entering into payment obligations under this Agreement it has not relied on the
      future availability of any Software Update, improvement, release or any other version or
      functionality.</p>
    <p>3.0 CONFIDENTIALITY AND PROPRIETARY RIGHTS</p>
    <p>3.1 Confidential Information. Customer acknowledges that the Software[, the Documentation] and all
      related technical and product information (inclusive of all trade secrets, ideas, concepts, processes,
      procedures, and know-how contained therein) disclosed in connection with this Agreement, in any form, is
      the confidential and proprietary information of PORTIA (Confidential Information). Except as and to the
      extent expressly authorized hereunder, Customer may use the Confidential Information only to the extent
      required in the exercise of its rights hereunder. Customer shall hold the Confidential Information in
      confidence and shall protect the Confidential Information from harm, loss, theft, reproduction and
      unauthorized access and shall ensure that such Confidential Information is not disclosed, published,
      released, transferred or otherwise made available in any form to, for the use or benefit of, any other
      person except as provided in this Agreement, without PORTIA’s prior written approval. Customer agrees that
      the Confidential Information is the sole and exclusive property of PORTIA. PORTIA shall not be under any
      obligation to make any particular Confidential Information available to Customer or to supplement or
      update any Confidential Information previously furnished.</p>
    <p>3.2 Ownership of Software. Customer acknowledges and agrees that all right, title and interest in and to
      the Software (including all intellectual property rights therein, including all copyright) and the
      Documentation is, and shall be, owned solely and exclusively by PORTIA and/or its third party licensors,
      if any. Nothing in this Agreement shall, or shall be deemed or construed to, assign, transfer or convey to
      Customer any title, rights or interest in any intellectual property, including copyrights, methodologies,
      ideas and concepts, in or to the Software or the Documentation other than the Licence or other rights
      specifically and expressly granted herein.</p>
    <p>3.3 Relief. Customer acknowledges and agrees that damages may be an inadequate remedy for a breach of
      this Article 3.0 or the provisions of this Agreement relating to Customer’s access to and use of the
      Software and agrees that such breach shall constitute irreparable harm to PORTIA. Customer agrees not to
      contest or object to an application for equitable relief by PORTIA in such circumstances and waives any
      and all immunities from injunctive relief to which it may be entitled. Any such relief or remedy shall not
      be exclusive, but shall be in addition to all other available legal or equitable remedies. Customer agrees
      that the provisions of this Section 3.3 are fair and reasonable in the commercial circumstances of this
      Agreement, and that PORTIA would not have entered into this Agreement but for the provisions of this
      Section 3.3.</p>
    <p>3.4 Ownership of Data. Customer acknowledges and agrees that all right, title and interest in and to any
      and all data collected by Customer or any Authorized User through Customer’s or an Authorized User’s use
      of the Software, or inputted by Customer or an Authorized User into the Software (Data) (including all
      Personal Information and intellectual property rights therein) is, and shall be, as between Customer and
      PORTIA, owned exclusively by Customer. Customer hereby grants to PORTIA, subject to the terms and
      conditions of this Agreement, a perpetual, worldwide, limited, sub-licensable, royalty-free,
      non-exclusive, personal, non-transferable and non-assignable license to access, use and disclose the Data
      only in aggregated, anonymous form, without any Personal Information (as defined herein). Customer agrees
      that PORTIA will not create a back-up of any Data.</p>
    <p>3.5 Personal Information. Customer agrees that in using the Software it shall comply at all times with
      all Privacy Laws (as defined herein), including in connection with its collection, use and disclosure of
      personal information and personal health information as defined by Privacy Laws (Personal Information).
      Customer covenants that any and all Personal Information will be collected with the consent of each
      individual to which such Personal Information relates (if such consent is required under applicable
      Privacy Laws), and covenants that such consents will be obtained by Customer in a manner that allows the
      parties hereto to use the Data as contemplated in this Agreement. Customer covenants and agrees that
      Personal Information in the Data will be used only for the purposes for which the Personal Information was
      initially collected or for a subsequent purpose for which consent is subsequently obtained, or has been
      collected, used or disclosed for a purpose in respect of which consent may, under applicable Privacy Laws,
      be implied, including as contemplated by this Agreement. Each of Customer and PORTIA shall use
      commercially reasonable efforts to protect and safeguard any Personal Information in the Data against
      unauthorized collection, use or disclosure, as provided by applicable Privacy Laws and shall cause its
      representatives, including in the case of Customer, the Authorized Users, to observe the terms of this
      Section 3.5.</p>
    <p>4.0 RISK MANAGEMENT</p>
    <p>4.1 No Warranties. CUSTOMER ACKNOWLEDGES THAT ITS USE THE SOFTWARE IS ENTIRELY AT ITS OWN RISK. CUSTOMER
      ACKNOWLEDGES THAT THE INTERNET IS NOT A SECURE MEDIUM AND THAT PRIVACY AND CONFIDENTIALITY CANNOT BE
      GUARANTEED. CUSTOMER EXPRESSLY ACKNOWLEDGES AND AGREES THAT THE SOFTWARE AND ALL RELATED INFORMATION,
      DATA, PRODUCTS AND/OR SERVICES OR ASSISTANCE PROVIDED BY PORTIA TO CUSTOMER, IS PROVIDED ON AN “AS
      AVAILABLE” AND “AS IS” BASIS WITHOUT ANY EXPRESS OR IMPLIED REPRESENTATION OR WARRANTY OR CONDITION OF ANY
      KIND OR NATURE WHATSOEVER INCLUDING WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
      PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS OR THAT THE SOFTWARE WILL BE ERROR FREE, OR CONCERNING
      THE SOFTWARE’S FUNCTIONALITY, QUALITY, SUITABILITY, USEFULNESS, RELIABILITY, SECURITY, COMPLETENESS,
      PERFORMANCE, OPERATION OR USE BY CUSTOMER AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A
      COURSE OF DEALING OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DENIED AND DISCLAIMED TO THE MAXIMUM
      EXTENT PERMITTED BY LAW. CUSTOMER CONFIRMS THAT IT HAS NOT RELIED ON ANY REPRESENTATION, WARRANTY,
      CONDITION, COVENANT OR PROMISE MADE BY PORTIA WHICH HAS NOT BEEN EXPRESSLY STATED IN THIS AGREEMENT.
      CUSTOMER ACKNOWLEDGES THAT THE ENTIRE RISK ARISING OUT OF ITS (OR ITS AUTHORIZED USER’S) ACCESS OR USE OF
      THE SOFTWARE, AND ANY SERVICES OR ASSISTANCE PROVIDED BY PORTIA IN ANY CONNECTION THEREWITH, REMAINS WITH
      CUSTOMER.</p>
    <p>4.2 Limitation. CUSTOMER AGREES THAT PORTIA (OR ITS AFFILIATES OR LICENSORS, IF ANY) ARE NOT, AND WILL
      NOT BE, LIABLE TO CUSTOMER UNDER THIS AGREEMENT FOR ANY LOSS OR DAMAGES OF ANY KIND OR NATURE WHATSOEVER,
      INCLUDING WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, CONSEQUENTIAL DAMAGES,
      OR LOSS OF DATA SUFFERED BY CUSTOMER OR ANY THIRD PARTY HOWSOEVER CAUSED AND REGARDLESS OF THE FORM OR
      CAUSE OF ACTION OR BASIS OF LIABILITY (INCLUDING FOR BREACH OF CONTRACT, TORT, NEGLIGENCE, BY STATUTE OR
      OTHERWISE), EVEN IF SUCH DAMAGES ARE FORESEEABLE OR PORTIA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES. CUSTOMER HEREBY ASSUMES ALL RISKS AND LIABILITIES IN CONNECTION WITH ITS (OR ITS AUTHORIZED
      USER’S) USE OF THE SOFTWARE, INCLUDING ANY INCOMPLETENESS, ERRORS, VIRUSES, BUGS, PROBLEMS, INACCURACIES,
      OR OTHER LIMITATIONS IN THE SOFTWARE OR ANY INTERRUPTION IN THE USE OF THE SOFTWARE . THIS SECTION SHALL
      SURVIVE ANY BREACH OR BREACHES AND/OR FAILURE OF THE ESSENTIAL PURPOSE OF THE AGREEMENT OR ANY REMEDY
      CONTAINED HEREIN. IF CUSTOMER IS DISSATISFIED WITH THE SOFTWARE OR WITH THIS AGREEMENT, CUSTOMER’S SOLE
      AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THIS SOFTWARE.</p>
    <p>4.3 Acknowledgement. CUSTOMER EXPRESSLY ACKNOWLEDGES AND CONFIRMS THAT THE LIMITATIONS AND EXCLUSIONS
      CONTAINED IN THIS AGREEMENT ARE FAIR AND REASONABLE IN THE CIRCUMSTANCES, AND THAT PORTIA WOULD NOT HAVE
      ENTERED INTO THIS AGREEMENT AND LICENSED THE SOFTWARE BUT FOR CUSTOMER’S AGREEMENT TO SUCH LIMITATIONS AND
      EXCLUSIONS.</p>
    <p>4.4 Indemnification. Customer hereby agrees to fully indemnify and hold harmless, and, upon PORTIA’s
      request, defend, PORTIA and its officers, directors, employees, agents, representatives, licensors, and
      all of their respective successors and assigns, harmless from and against all claims, damages and losses
      of any type, expenses (including reasonable legal fees), actions, demands, liabilities, settlements, or
      judgments that result from or arise out or which such persons may suffer or incur, directly or indirectly,
      as a result of or arising out of or in any way connected with Customer’s or an Authorized User’s use of
      the Software or breach of this Agreement.</p>
    <p>5.0 TERM AND TERMINATION</p>
    <p>5.1 Term. This initial term of this Agreement shall commence on the date that Customer enters into this
      Agreement (the Effective Date) and shall end on the last day of the month in which the Effective Date
      occurs (the Initial Term) and after the expiration of such Initial Term shall be renewed automatically
      thereafter for additional periods of one (1) month (each a Renewal Term and together with the Initial
      term, the Term), unless Customer gives notice to PORTIA of its intention to terminate this Agreement at
      least fourteen (14) days prior to the expiration of the then-current Term.</p>
    <p>5.2 Termination. This Agreement shall continue during the Term until terminated in accordance with the
      terms hereof. This Agreement may be terminated by PORTIA immediately in the event of a breach of this
      Agreement by Customer.</p>
    <p>5.3 Effects of Termination. Upon any termination of this Agreement: (a) the License shall immediately
      terminate and Customer shall cease all use of, and shall have no access to, the Software; and (b) Customer
      shall pay any and all License Fees accrued or payable to PORTIA prior to the effective date of
      termination.</p>
    <p>6.0 GENERAL PROVISIONS</p>
    <p>6.1 Entire Agreement, Law, etc.. (a) This Agreement constitutes the entire agreement between the parties
      hereto pertaining to all the matters herein and supersedes all prior agreements, proposals,
      understandings, negotiations and discussions between the parties, whether oral or written and may only be
      amended or modified by agreement of the authorized representatives of the parties. No additional term or
      condition included in any Customer purchase order or other document issued by Customer that has not
      previously been agreed to in writing by PORTIA shall bind PORTIA. If Customer is a corporation,
      organization, partnership or other non-individual entity, it shall cause its employees, agents,
      contractors, directors and officers to comply with these Terms of Service and shall be responsible at all
      times for all such employees, agents, contractors, directors and officers. (b) This Agreement shall be
      exclusively governed by, construed and interpreted in accordance with the laws of the Province of Ontario,
      Canada and the federal laws of Canada applicable therein. For the purpose of all legal proceedings, this
      Agreement shall be deemed to have been performed in the Province of Ontario, Canada and the parties hereto
      expressly confirm that the law of the Province of Ontario is the proper law. The parties hereto
      irrevocably attorn to the non-exclusive jurisdiction of the Courts of the Province of Ontario in respect
      of all matters and disputes arising hereunder. (c) It is the express wish of the parties hereto that this
      Agreement be drawn up in English. La volonté expresse des parties aux présentes est que ce Agreement soit
      rédigé en anglais. The parties hereto hereby waive any right to use and rely upon any other language. (e)
      This Agreement shall be binding upon and shall enure to the benefit of and be enforceable by each of the
      parties hereto, their respective successors and permitted assigns. Customer may not assign all or any part
      of this Agreement without the prior consent of PORTIA. PORTIA may assign this Agreement without the
      consent of Customer at any time. (f) No delay or omission by a party to exercise any right or power it has
      under this Agreement or to object to the failure of any covenant of the other party to be performed in a
      timely and complete manner, shall impair any such right or power or be construed as a waiver of any
      succeeding breach or any other covenant. All waivers must be in writing and signed by the party waiving
      its rights. (g) If any provision of this Agreement is held by a court of competent jurisdiction to be
      invalid or unenforceable in any respect, then the remaining provisions of this Agreement, or the
      application of such provisions to persons or circumstances other than those as to which it is invalid or
      unenforceable shall not be affected thereby, and each such provision of this Agreement shall be valid and
      enforceable to the extent granted by law. (h) The provisions of Sections 2.0, 3.0, 4.0, 5.3, 6.0 and any
      other terms herein which expressly state that such terms will survive shall survive the termination or
      expiration of this Agreement for any reason. (i) Except as otherwise provided in this Agreement, all
      notices, requests, claims, demands and other communications required or permitted to be given hereunder
      shall be in writing and shall be given or made (and shall be deemed to have been duly given or made upon
      receipt) by delivery in person, by commercial overnight courier service (with recipient signature
      required), by registered or certified mail (postage prepaid, return receipt requested), or by e-mail with
      confirmation of receipt of such e-mail by the receiving party to the respective party at the addresses or
      e-mail address for each provided to the other, as may be changed by either party in accordance with the
      terms of this Section. (j) In this Agreement: (i) all usage of the word “including” or “include” or the
      phrase “e.g.,” in this Agreement shall mean “including, without limitation”; (ii) the division of this
      Agreement into separate Articles, Sections and Subsections and the insertion of headings is for
      convenience of reference only and shall not affect the construction or interpretation of this Agreement;
      (iii) words or abbreviations which have well-known or trade meanings are used herein in accordance with
      their recognized meanings; (iv) Business Day means any day other than a Saturday, Sunday or a statutory or
      civic holiday in the city of Toronto, Ontario; (v) if any action is required to be taken pursuant to this
      Agreement on a day which is not a Business Day, then such payment or action, as the case may be, shall be
      made or taken on the next day that is Business Day; (vi) unless otherwise specified, all amounts referred
      to in this Agreement are in USD and will be payable in USD; and (vii) the terms and conditions of this
      Agreement shall not be construed in favour of or against any party hereto by reason of the extent to which
      any party or its professional advisors participated in the preparation of this Agreement.</p>
  </>
)
/* eslint-enable */

export default UserAgreement
