import React, { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FaEnvelope, FaLock } from 'react-icons/fa'

import Button from '../components/Button'
import Card from '../components/cards'
import Col from '../components/layouts/Col'
import Form from '../components/forms'
import Icon from '../components/Icon'
import InputGroup from '../components/inputs'
import LoginFormModel from '../models/LoginFormModel'
import Modal from '../components/modals'
import ValidatedForm from '../components/ValidatedForm'

import { network } from '../util/constants'
import logo from '../img/logo.png'
import modelOf from '../util/modelOf'
import styles from '../styles/LoginView.module.css'

const LoginView = ({ model }) => {
  const [ showModal, setShowModal ] = useState(false)
  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)
  return (
    <div className={ classNames('h-100 p-5', styles.container) }>
      <Modal
        centered
        show={ showModal }
        onHide={ handleHideModal }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>An unexpected error has occurred. Please try again later.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleHideModal }>Close</Button>
        </Modal.Footer>
      </Modal>
      <Card md className={ styles.card }>
        <Card.Body>
          <div className='text-center mb-3'>
            <img src={ logo } alt='Portia ABA Clinic Software' />
          </div>
          <div className='text-center mb-3'>
            <span className='text-uppercase font-weight-bold mb-lg'>Sign in.</span>
          </div>
          <ValidatedForm
            onSubmit={ model.submitForm }
            onError={ handleShowModal }
            data={ model.formState }
            rules={ model.validationRules }
            render={ ({ errors, serverErrors, requestState }) => (
              <>
                <Form.Group controlId='email'>
                  <InputGroup>
                    <Form.Control
                      placeholder='Enter email'
                      name='email'
                      isInvalid={ errors.has('email') || serverErrors.has('email') }
                      { ...model.getFieldProps('email') }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Icon variant='secondary'><FaEnvelope /></Icon>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type='invalid'>
                      { errors.first('email') }
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>
                      { serverErrors.first('email') }
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId='password'>
                  <InputGroup>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      name='password'
                      isInvalid={ errors.has('password') || serverErrors.has('email') }
                      { ...model.getFieldProps('password') }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Icon variant='secondary'><FaLock /></Icon>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type='invalid'>
                      { errors.first('password') }
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Row>
                  <Form.Group as={ Col }>
                    <Form.Check
                      type='checkbox'
                      label='Remember me'
                      id='rememberMe'
                      name='rememberMe'
                      { ...model.getFieldProps('rememberMe') }
                    />
                  </Form.Group>
                  <Form.Group as={ Col } className='text-right'>
                    Forgot your password?
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={ Col }>
                    <Button type='submit' className='w-100' disabled={ requestState === network.PENDING }>
                      Login
                    </Button>
                  </Form.Group>
                </Form.Row>
              </>
            ) }
          />
        </Card.Body>
      </Card>
    </div>
  )
}

LoginView.propTypes = {
  model: modelOf(LoginFormModel).isRequired,
}

export default observer(LoginView)
