import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardImg = ({ children, ...rest }) => <Card.Img {...rest}>{children}</Card.Img>

MyCardImg.displayName = 'Card.Img'

export default MyCardImg

MyCardImg.propTypes = {
  children: PropTypes.node.isRequired,
}
