import Body from './Body'
import Card from './Card'
import Footer from './Footer'
import Header from './Header'
import Img from './Img'
import Link from './Link'
import Subtitle from './Subtitle'
import Text from './Text'
import Title from './Title'

Card.Body = Body
Card.Footer = Footer
Card.Header = Header
Card.Img = Img
Card.Link = Link
Card.Subtitle = Subtitle
Card.Text = Text
Card.Title = Title

export default Card
