import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'

const MyButton = ({ children, ...rest }) => <Button {...rest}>{children}</Button>

MyButton.displayName = 'Button'

export default MyButton

MyButton.propTypes = {
  children: PropTypes.node.isRequired,
}
