import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardHeader = ({ children, ...rest }) => <Card.Header {...rest}>{children}</Card.Header>

MyCardHeader.displayName = 'Card.Header'

export default MyCardHeader

MyCardHeader.propTypes = {
  children: PropTypes.node.isRequired,
}
