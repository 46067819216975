import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

const MyCardFooter = ({ children, ...rest }) => <Card.Footer {...rest}>{children}</Card.Footer>

MyCardFooter.displayName = 'Card.Footer'

export default MyCardFooter

MyCardFooter.propTypes = {
  children: PropTypes.node.isRequired,
}
