// Serves as a base for all other form models to inherit from.
// This model has no data. It defines functions to handle field updates, reading form state, etc.

import { getSnapshot, types } from 'mobx-state-tree'

const FormModel = types
  .model('FormModel', {})
  .actions(self => ({
    handleFieldChange(e) {
      if (typeof self[e.target.name] === 'undefined') {
        throw new Error(`Attempted to handleFieldChange for undefined field '${ e.target.name }'.`
                        + ' Is this field defined on the model?')
      }
      switch (e.target.type) {
        case 'checkbox':
          self[e.target.name] = e.target.checked
          break;
        default:
          self[e.target.name] = e.target.value
      }
    },
  }))
  .views(self => ({
    getFieldProps(name) {
      if (typeof self[name] === 'undefined') {
        throw new Error(`Attempted to getFieldProps for undefined field '${ name }'.`
                        + ' Is this field defined on the model?')
      }
      return {
        value: self[name],
        onChange: self.handleFieldChange,
      }
    },
    get formState() {
      return getSnapshot(self)
    },
  }))

export default FormModel
