import React, { useEffect, useState } from 'react'

import LoginView from '../views/LoginView'
import LoginFormModel from '../models/LoginFormModel'

const LoginController = () => {
  const [ model, setModel ] = useState()

  // Empty array means this effect only runs once
  useEffect(() => {
    setModel(LoginFormModel.create())
  }, [])

  return model ? <LoginView model={ model } /> : null
}

export default LoginController
