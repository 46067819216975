// The server performs its own validation and returns its own error messages.
// This class parses those error messages and returns an object similar to ValidatorJS.
// This way we can have similar interfaces between the two error reporting mechanisms.
// For reference: https://www.npmjs.com/package/validatorjs#error-messages
//
// Alternatively we might be able to use asynchronous validation, however I think that would be too complex.
// The server may return status codes instead of text errors in the future. In that case this class remains useful.
// Async validation reference: https://www.npmjs.com/package/validatorjs#asynchronous-validation

class ServerErrorParser {
  // errors: an object of arrays of error messages
  // e.g.: {"password":["This password is too short.", "This password is just too common. Please try another!"]}
  constructor(errors) {
    this.errors = new Map()
    if (!errors) return
    Object.keys(errors).forEach(key => {
      this.errors.set(key, errors[key])
    })
  }

  first(key) {
    return this.errors.has(key)
      ? this.errors.get(key)[0]
      : false
  }

  get(key) {
    return this.errors.has(key)
      ? this.errors.get(key)
      : []
  }

  all() {
    const allErrors = {}
    this.errors.forEach((value, key) => {
      allErrors[key] = value
    })
    return allErrors
  }

  has(key) {
    return this.errors.has(key)
  }
}

export default ServerErrorParser
