import Modal from './Modal'
import Body from './Body'
import Dialog from './Dialog'
import Footer from './Footer'
import Header from './Header'
import Title from './Title'

Modal.Body = Body
Modal.Dialog = Dialog
Modal.Footer = Footer
Modal.Header = Header
Modal.Title = Title

export default Modal
