import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

import styles from '../../styles/Card.module.css'

const MyCard = ({
  className,
  children,
  sm,
  md,
  lg,
  ...rest
}) => {
  const classes = {
    [styles.base]: true,
    [styles.sm]: !!sm,
    [styles.md]: !!md,
    [styles.lg]: !!lg,
  }
  return (
    <Card className={ classNames(className, classes) } { ...rest }>{ children }</Card>
  )
}

MyCard.displayName = 'Card'

export default MyCard

MyCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
}

MyCard.defaultProps = {
  className: null,
  sm: null,
  md: null,
  lg: null,
}
