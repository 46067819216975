import React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'

const MyInputGroup = ({ children, ...rest }) => <InputGroup { ...rest }>{ children }</InputGroup>

MyInputGroup.displayName = 'InputGroup'

MyInputGroup.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MyInputGroup
