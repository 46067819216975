import React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'

const MyRadio = ({ children, ...rest }) => <InputGroup.Radio { ...rest }>{ children }</InputGroup.Radio>

MyRadio.displayName = 'Radio'

MyRadio.propTypes = {
  children: PropTypes.node,
}

MyRadio.defaultProps = {
  children: null,
}

export default MyRadio
