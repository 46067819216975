import { types } from 'mobx-state-tree'
import Axios from 'axios'

import FormModel from './FormModel'

const RegisterFormBase = types
  .model('RegisterFormBase', {
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    password_confirmation: types.optional(types.string, ''),
  })
  .actions(() => ({
    async submitForm(e) {
      const form = new FormData(e.target)
      return Axios({
        method: 'post',
        url: '/api/register',
        data: form,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        // todo
        // .then(res => {
        //   console.log(res)
        // })
        .catch(res => {
          // Watches for validation errors returned by the server.
          if (res.response && res.response.data) {
            throw res.response.data
          }
          throw res
        })
    },
  }))
  .views(() => ({
    get validationRules() {
      return {
        first_name: 'required|max:255',
        last_name: 'required|max:255',
        email: 'required|email|max:255',
        password: 'required|confirmed|min:8',
        password_confirmation: 'required',
      }
    },
  }))

const RegisterFormModel = types
  .compose(FormModel, RegisterFormBase)
  .named('RegisterFormModel')

export default RegisterFormModel
