import { flow, types } from 'mobx-state-tree'
import axios from 'axios'

import User from '../models/UserModel'

const UserStore = types
  .model('UserStore', {
    users: types.map(User),
  })
  .actions(self => ({
    fetchUser: flow(function* fetchUser(id) {
      let res;
      try {
        res = yield axios.get(`http://localhost:3001/users/${ id }`)
        self.users.put(res.data)
      } catch (e) {
        console.error('Error fetcing user: ', e)
      }
      return res.data
    }),
  }))
  .views(self => ({
    getUser(id) {
      return self.users.get(id)
    },
  }))

export default UserStore
