import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import iconStyles from '../styles/Icon.module.scss'

const Icon = ({
  circle,
  className,
  children,
  size,
  variant,
}) => {
  const iconClasses = {
    [className]: !!className,
    [iconStyles[variant]]: !!variant,
    [iconStyles[size]]: !!size,
    [iconStyles.circle]: !!circle,
    [iconStyles[`${ variant }-bg`]]: !!circle,
  }
  return (
    <span className={ classNames(iconClasses) }>
      { children }
    </span>
  )
}

export default Icon

Icon.propTypes = {
  circle: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'danger',
    'info',
    'light',
    'dark',
  ]),
}

Icon.defaultProps = {
  circle: false,
  className: '',
  size: '',
  variant: 'primary',
}
